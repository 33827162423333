import React from 'react'
import {StaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import image1 from '../images/home_image1.jpg'
import image2 from '../images/home_image2.jpg'
import parse from 'html-react-parser'
import Seo from '../components/global/Seo'

export default function Homepage({data}) {
  return (
    <StaticQuery
      query={graphql`
        query HomepageQuery {
          wpPage(title:{eq:"Home"}) {
            content
            acfHome {
              footerCtaLink {
                ... on WpPage {
                  slug
                }
              }
              footerCtaBackgroundImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              footerCtaText
              imageLinkPods {
                text
                image{
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                link {
                  ... on WpPage {
                    slug
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div className='home homepage'>
          <Seo title="Home" description="With origins dating from 1874, the distinguished Manchester Tennis &amp; Racquet Club (MTRC) has been situated on Blackfriars Road in Salford since 1880. This truly unique members' club is one of Manchester's greatest hidden treasures."  />
          <ul className="image_link_pods-noHover">
            <li className="slide"><img src={image1} alt="Home image 1" /></li>
            <li className="slide"><img src={image2} alt="Home image 2" /></li>
          </ul>

          <div className="intro">
              <h1>The Club</h1>
              <div className="the-content">{parse(data.wpPage.content)}</div>
          </div>

          <ul className="image_link_pods">
            {
              data.wpPage.acfHome.imageLinkPods.map(link => {
                console.log(link)
                return (
                  <li className="slide">
                      <Link to={link.link.slug}>                
                        <h3>{link.text}</h3>
                        <GatsbyImage image={link.image.localFile.childImageSharp.gatsbyImageData} />
                      </Link>
                  </li>
                )
              })
            }
          </ul>

          <div className="benefits homepage-benefits">
            <h2>ADDITIONAL BENEFITS</h2>
            <ul>
              <li>
                <h4>ACCOMODATION</h4>
                <p>Our members benefit from special set rates with our Manchester accommodation partner CitySuites, located approximately a two minute walk around the corner from MTRC. CitySuites is Manchester's only aparthotel with an 18m indoor swimming pool, jacuzzi, state of the art gym, 24-hour reception and concierge.</p>
              </li>
              <li>
                <h4>RECIPROCAL CLUBS</h4>
                <p>Members can book into our carefully selected reciprocal clubs around the globe such as London's Savile Club; a truly wonderful place to stay, host meetings, dine and entertain when in London on business or pleasure.</p>
              </li>
            </ul>
          </div>
          
          <Link to={data.wpPage.acfHome.footerCtaLink.slug} className="footer-cta">
            <GatsbyImage image={data.wpPage.acfHome.footerCtaBackgroundImage.localFile.childImageSharp.gatsbyImageData}  alt={'CTA Image'}/>
              <div className="content">
                  <div className="block">
                      <div className="centered">
                        {data.wpPage.acfHome.footerCtaText}
                      </div>
                  </div>
              </div>
          </Link>    
        </div>
      )}
    />
  )
}
